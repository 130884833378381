import { getNow } from "../globals";

export const getNotes = async (token,uid) => {
    try {
        const url =`https://appserver.edwardspower.co.uk:3446/notes/get/${uid}`;
        const requestOptions = {
            method: 'GET',
            headers: new Headers({'Content-Type': 'application/json','Authorization' : 'Bearer ' + token })
        };
        const res = await fetch(url, requestOptions)
        if(res.status ===200) {
            const ret = await res.json()
            let data = JSON.parse(JSON.stringify(ret.data))
            return {status: true, data, err: ''}
        }else {
            return {status: false, data: null, err: `API Access returned with ${res.msg} (${res.status})`}
        }
    }catch(err) {
        return {status: false, data: null, err: `API Access returned with Catch Error ${err}`}
    }
}

export const addNote= async (token,uid,note,added_by) => {
    try {
        const data = {uid,note,added_by,date_added: getNow()}
        const url =`https://appserver.edwardspower.co.uk:3446/notes/add`;
        const requestOptions = {
            method: 'POST',
            headers: new Headers({'Content-Type': 'application/json','Authorization' : 'Bearer ' + token }),
            body: JSON.stringify(data)
        };
        const res = await fetch(url, requestOptions)
        if(res.status ===200) {
            const ret = await res.json()
            let data = JSON.parse(JSON.stringify(ret.data))
            return {status: true, data, err: ''}
        }else {
            return {status: false, data: null, err: `API Access returned with ${res.msg} (${res.status})`}
        }
    }catch(err) {
        return {status: false, data: null, err: `API Access returned with Catch Error ${err}`}
    }
}