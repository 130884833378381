
export const APILogon = async (username,password) => {

    try {
        const url ="https://crm.edwardspower.co.uk:3446/auth/signin";
        const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username,password})
        };
        const res = await fetch(url, requestOptions)
        //var ret
        if(res.status ===200) {
            const data = await res.json()
            console.log(data)
            //ret = data.data
            if(!data.status) {
                return {status: false, err: "Invalid Logon Details"}
            }
            return {status: data.status, data: data.data}
        }else {
            return {status: false, err: "Invalid Logon Details"}
        }
    }catch(err) {
        return {status: false, err: `Catch Error Logging on ${err}`} 
    }
}

export const userlist = async (token) => {

    try {
        const url ="https://crm.edwardspower.co.uk:3446/users/userlist";
        const requestOptions = {
        method: 'GET',
        headers: new Headers({'Content-Type': 'application/json','Authorization' : 'Bearer ' + token }),
        };
        const res = await fetch(url, requestOptions)

        if(res.status ===200) {
            const ret = await res.json()
            return ret
            // return {status: data.status, data: data.data, err:''}
        }else {
            return {status: false, err: "Error getting userlist"}
        }
    }catch(err) {
        return {status: false, err: `Catch Error Getting userlist on ${err}`} 
    }
}