export const outputLeadToHubspot = async (token, lead) => {

  try {
    console.log(lead)
    const url ="https://appserver.edwardspower.co.uk:3446/hubspot/createtpcontact";
    const requestOptions = {
      method: 'POST',
      headers: new Headers({'Content-Type': 'application/json','Authorization' : 'Bearer ' + token }),
      body: JSON.stringify(lead)
    };
    const res = await fetch(url, requestOptions)
    let data = null
    if(res.status===200) {
      let ret = await res.json()
      return ret
    }
    return {status: false, data: null, err: `Output. ${res.msg} (${res.status})` }
  }catch(err) {
    return {status: false, data: null, err: `Error sending lead to Hubspot (${JSON.stringify(err)})` }
  }
}