import { createSlice } from '@reduxjs/toolkit'

const   initialState ={
    refreshinterval: 0, 
    gridfontsize: '10pt',
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    updateRefreshesettings: (state,action) => {
        if(!isNaN(parseInt(action.payload)))
            state.refreshinterval = parseInt(action.payload)
    },
    updateGridfontsettings: (state,action) => {
      state.gridfontsize = action.payload
    }
  }
});

export const { updateRefreshesettings, updateGridfontsettings } = settingsSlice.actions
export default  settingsSlice.reducer;