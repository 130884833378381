import { createSlice } from '@reduxjs/toolkit'

const   initialState ={auth:{ _id: '', token:'', username: '', email: '', 
          security: {
            canview: false, 
            canedit: false, 
            canupdate: false,
            candownload: false, 
            canupdatetitle: false,
            canupdatefirst: false,
            canupdatelast: false,
            canupdateaddr1: false,
            canupdateaddr2: false,
            canupdateaddr3: false,
            canupdatepostcode: false,
            canupdatephone: false,
            canupdatephone1: false,
            canupdateemail: false,

          } 
        }
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
          addauth: (state,action) => {
            state.auth = action.payload
          },
          updateauth: (state,action) => {
            state.auth = [...state.auth,...action.payload]
          }
  }
});
export const { addauth, updateauth} = authSlice.actions
export default  authSlice.reducer;