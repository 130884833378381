import React, { useState, useEffect} from 'react';
import './App.css';
import Button from '@mui/material/Button';
import Logon from './Logon'
import GridView from './GridView'
import EPAppBar from './EPAppBar'
import UserAdmin from './UserAdmin'
import SaveColumns from './SaveColumns'

import FormsBuilder from './FormsBuilder.js'

import { useSelector, useDispatch } from 'react-redux'
import { initilizeusers, initilizestatuses, initilizetitles } from './slices/systablesSlice.js'
import {userlist} from './APIS/userAPI'
import {getsystables} from './APIS/datadictAPI'

function App() {
  const [loggedon, setLoggedOn] = useState(false)
  const [token,setToken] = useState('')
  const [menuitem, setMenuItem] = useState('gridview')
  const [loaddata, setLoadData] = useState(false)
  const dispatch = useDispatch()
  const systables = useSelector(state => state.systables)

  // Get our Initial data w eneed to use throughout the system - Systables etc
  useEffect(()=> {
    const initialize = async () => {
      // set up user list
      const users = await userlist(token);
      console.log('userlist returned')
      console.log(users)
      if(users.status) {
        console.log('APP -> Initialise users')
        console.log(users)
        var userpairs = []
        users.data.forEach((user) => {
          userpairs.push({label: user.name, value: user._id})
        })
        console.log(userpairs)
        dispatch(initilizeusers(userpairs))
      }
      // setup titles list
      const titles= await getsystables(token,'titles')
      if(titles.status){
        console.log('APP -> Initialise titles')
        console.log(titles)
        dispatch(initilizetitles(titles.data))
      }
      // setup statuses
      const statuses= await getsystables(token,'statuses')
      if(statuses.status){
        console.log('APP -> Initialise statuses')
        console.log(statuses)
        dispatch(initilizestatuses(statuses.data))
      }
    }
    if(token)
      initialize()
  },[token])

  useEffect(()=> {
    if(!loaddata) {
      if(systables.titles.length > 0 && systables.statuses.length > 0 && systables.users.length > 0 )
        setLoadData(true)
    }
  },[systables,loaddata])

  const updateLogon = (payload) => {
    setLoggedOn(payload.loggedon)
    setToken(payload.token)
  }

  const setMenu = (item) => {
    if(item === 'logout') 
      setLoggedOn(false)
    else  
      setMenuItem(item)
  }



  if(!loggedon) return (
    <Logon onChange={updateLogon} />
  )
  if(loggedon && !loaddata)
    return (<h2>Data Loading, Please Wait....</h2>)

  if(loggedon && loaddata) return (
    <>
      <EPAppBar menuSelect={setMenu}/>
      {menuitem === 'gridview' &&
        <GridView />
      }

      {menuitem === 'useradmin' &&
        <UserAdmin />
      }

      {menuitem === 'comingsoon' && 
        <>
          <h4>Coming Soon....</h4>
          <Button onClick={() => setMenuItem('gridview')} >&lt; Back To Data Grid</Button>
        </>
      }
      {menuitem === 'createdatadict' &&
        <SaveColumns update={true} leadset='localmoneyadvisor' />
      }

      {menuitem==='forms' &&
        <FormsBuilder />
      }
    </>
  );
}

export default App;
