import { createSlice } from '@reduxjs/toolkit'

const   initialState ={
    users:[], 
    statuses: [],
    titles: [] 
}

export const systablesSlice = createSlice({
  name: 'systables',
  initialState: initialState,
  reducers: {
    initilizeusers: (state,action) => {
        state.users = action.payload
    },
    initilizestatuses: (state,action) => {
        state.statuses = action.payload
    },
    initilizetitles: (state,action) => {
        state.titles = action.payload
    }
  }
});
export const { initilizeusers, initilizestatuses, initilizetitles} = systablesSlice.actions
export default  systablesSlice.reducer;