import * as React from 'react';

function UserAdmin(props) {


    return (
        <div>
            <h4>UserAdmin</h4>
        </div>
    )
}
export default UserAdmin