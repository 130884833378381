import React, { useEffect} from 'react';

import {
    GridRowModes,
    GridActionsCellItem,
} from '@mui/x-data-grid';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector } from 'react-redux'
import { addDD } from './APIS/datadictAPI.js'
const handleSaveClick = (id) => {}
const handleDeleteClick = (id) => {}
const handleCancelClick = (id) => {}
const handleViewClick = (id) => {}

const SaveColumns = (props) => {
    const [rowModesModel, setRowModesModel] = React.useState({});
    const auth = useSelector(state => state.auth.auth)
    const getDateSetter = (dtin) => {
        try {
          return new Date(dtin).toLocaleString('en-GB', { timeZone: 'UTC' })
        }catch(err){
          return dtin
        }
      }
      const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
      };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: '_id', headerName: 'uid', width: 10, hideable: true, visible: false },
        { field: 'created', headerName: 'Created Date', width: 160 , valueGetter: getDateSetter  }, 
        { field: 'title', headerName: 'Title',editable: auth.security.canupdatetitle??false , width: 100, type: 'singleSelect',
            valueOptions: [], valueLookup: {leadset: "localmoneyadvisor", table: "title" }
        },
        { field: 'firstname', headerName: 'First name',editable: auth.security.canupdatefirst??false , width: 140 },
        { field: 'lastname', headerName: 'Last name', width: 140, editable: auth.security.canupdatelast??false },
        { field: 'postcode', headerName: 'Postcode', width: 80, editable:  auth.security.canupdatepostcode??false, valueGetter: (value) => {return value.toUpperCase()} },
        { field: 'email', headerName: 'Email Address', editable:  auth.security.canupdateemail, width: 200 },
        {field: 'phone',headerName: 'Phone',editable:  auth.security.canupdatephone ,width: 120},
        {field: 'userfield1',headerName: 'Home Ownership',width: 150,editable: true, type: 'singleSelect',
        valueOptions: [], valueLookup: {leadset: "localmoneyadvisor", table: "userfield1"}
        },
        {field: 'userfield2',headerName: 'LPA For..',editable: true,width: 150,type: 'singleSelect',
        valueOptions: [], valueLookup: {leadset: "localmomneyadvisor", table: "userfield2"}},
        {field: 'userfield3',headerName: 'LPA Type',editable: true,width: 150, type: 'singleSelect',
        valueOptions: [],valueLookup: {leadset: "localmomneyadvisor", table: "userfield3"}},
        {field: 'status',headerName: 'Status',editable: true,width: 160,type: 'singleSelect',
        valueOptions: [], valueLookup: {leadset: "localmomneyadvisor", table: "statuses"}},
        {field: 'user',headerName: 'Assigned User',width: 130},
        {field: 'downloaded',headerName:'Downloaded',type: 'boolean', width:100}
    ]

    columns.push({
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            const canedit = auth.security.canedit??false

            if (isInEditMode) {
            return [
                <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                    color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
                />,
            ];
            }

            if(canedit)
            return [
            <GridActionsCellItem
                icon={<VisibilityIcon />}
                label="Edit / View"
                className="textPrimary"
                onClick={() => handleViewClick(id)}
                color="inherit"
            />,
            <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
            />,
            <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
            />,
            ];

            return [
            <GridActionsCellItem
                icon={<VisibilityIcon />}
                label="Edit / View"
                className="textPrimary"
                onClick={() => handleViewClick(id)}
                color="inherit"
            />
            ]
        }
    })



    useEffect(()=>{
        const updateColumnsInMongo = async () => {
            try {
                const datadict = JSON.stringify(columns, function(columns, value) {
                    if (typeof value === "function") {
                      return "/Function(" + value.toString() + ")/";
                    }
                    return value;
                  });
                console.log(datadict)
                await addDD(auth.token, props.leadset, datadict )
            }catch(err) {
                console.log(`SaveColumns -> useEffect->Catch`)
                console.log(err)
            }
        }
        if(props.update)
            updateColumnsInMongo()
    },[props.update, props.leadset, auth.token])

}

export default SaveColumns;