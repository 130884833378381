import React, { useState, useEffect} from 'react';
import './App.css';
import './css/RecordEdit.css';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import Textarea from '@mui/joy/Textarea';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import DehazeSharpIcon from '@mui/icons-material/DehazeSharp';
import TransitionAlert from './components/TransitionAlert';
import { MuiTelInput } from 'mui-tel-input'
import { useSelector } from 'react-redux'
import {addNote,getNotes} from './APIS/notesAPI'
import {getNow} from './globals'


function RecordEdit(props) {
  const [open, setOpen] = React.useState(false);
  const [opennote, setOpenNote] = useState(false)
  const [data,setData]=useState(props.data)
  const [isloading,setIsLoading] = useState(false)
  const [notes, setNotes] = useState([])
  const [note,setNote] = useState('')
  const [selnote,setSelNote]=useState(-1)
  const [id, setId] = useState(null)
  const [openalert, setOpenAlert] = useState(false) 
  const [alertmsg, setAlertMsg] = useState("")
  const auth = useSelector(state => state.auth.auth)



  useEffect(()=> { 
    async function LoadData() {
      const ret = await getNotes(auth.token, props.data._id)
      if(ret.status)
        setNotes(ret.data)
    }
    if(!open) {
      console.log(`RecordEdit props.open set to ${props.open}`)
      setOpen(props.open)
      setData(props.data)
    }
    if(props.open && auth.token > "" && typeof props.data !== 'undefined' && props.data._id > "" )
      LoadData();
  },[props.open, props.data, auth.token]);

  const handleCloseNote = () => { setOpenNote(false) }
  
  const handleShowNote = (id) => {
    setSelNote(id)
    setOpenNote(true)
  }

  const handleClose = () => {
    setOpen(false);
    props.setOpen()
  };

  const handleUpdate = async () => {
    const newRow = JSON.parse(JSON.stringify(data))
    await props.onUpdate(newRow)
    setAlertMsg("Update to Database compelted.")
    setOpenAlert(true)
  }

  const handleAddNote = async (e) => {
    console.log(auth);
    console.log(`id set to ${data._id}`)
    const dateadded = getNow();
    setNotes(prevNotes => [...prevNotes, {note, user: auth.user, dateadded}])
    const ret = await addNote(auth.token,data._id,note,auth.user)
    console.log(ret)
    if(!ret.status) {
      setAlertMsg(`Error adding note ${ret.err}`)
      setOpenAlert(true)
    }
  }

  const updateNote = (e) => {
    setNote(e.target.value)
  }

  const updateField = ({target:{name, value}}) =>{
    console.log(`updateField with ${name} ${value}`)
    setData( (prevData) => ( {...prevData,[name]: value} ));
  }
  const updatePhone = (field) => (value) => {
    setData( (prevData) => ( {...prevData,[field]: value} ));
  }


  const updateChecked = (event) => {
    console.log(event);
  }


  const addTextField = (item) => {
    return (
      <div className='fielddivider' >
            <TextField  
              label={item.headerName}
              id={item.field}
              name={item.field}  
              type='text'
              value={(typeof item.valueGetter==='undefined')?data[item.field]??'':item.valueGetter(data[item.field]??'') }
              onChange={updateField}
              variant="standard"
              sx={{fontSize: '8pt'}}
              readOnly={!item.editable}
            />            
      </div>

    )
  }
  const addDateField = (item) => {
    return (
      <div className='fielddivider' >
      <InputLabel shrink={true} htmlFor={item.field} sx={{backgroundColor: 'white'}}>{item.headerName}&nbsp;</InputLabel>
      <Input  
        label={item.headerName}
        id={item.field}
        name={item.field}  
        type='date'
        value={data[item.field]??''}
        onChange={updateField}
        variant="standard"
        sx={{fontSize: '8pt'}}
        readOnly={!item.editable}
      />
      </div>
    )
  }

  const addPhoneField = (item) => {
    return (
      <div className='fielddivider'>
      <MuiTelInput  
        defaultCountry="GB"  
        label={item.headerName}
        id={item.field}
        name={item.field}  
        type='tel'
        value={data[item.field]??''}
        onChange={updatePhone(item.field)}
        variant="standard"
        readOnly={!item.editable}
      />
        <Button variant="outlined" size='small' className='btn_dial' >
          <a href={`tel: ${data[item.field]??''}`} >Click To Dial</a>
        </Button>
      </div>
    )
  }

  const addSelectField = (item) => {
    return (
      <div className='fielddivider' >

      <FormControl style={{ width: "100%", marginTop: 10, marginBottom: 5 }} size="small">
        <InputLabel shrink={true} htmlFor={item.field} sx={{backgroundColor: 'white'}}>{item.headerName}&nbsp;</InputLabel>
        <Select
          style={{ width: "90%"}}
          label={`lbl_${item.field}`}
          id={item.field}
          name={item.field}  
          value={data[item.field]??''}
          displayEmpty
          onChange={updateField}
          readOnly={!item.editable}
        >
          {item.valueOptions.map((item, index) => {
            return (        
              <MenuItem key={`${item.field}_${index}`} value={item.value} >
                {item.label}
              </MenuItem>
              )
          })}
        </Select>
      </FormControl>
      </div>
    )
  }
  const addCheckBoxField = (item) => {
    return (
      <div className='fielddivider' >

      <FormControlLabel
      label={item.headerName}
      control={<Checkbox onChange={updateChecked} id={item.field} checked={data[item.field]??false} readOnly={!item.editable} />}
      />
      </div>
    )
  }

  const chooseConrol = (item) => {
    switch((item.type??'').toLowerCase()) {
      case 'text':
        if(typeof item.isphone !=='undefined' && item.isphone)
          return addPhoneField(item)
        return addTextField(item)
      case 'date':
        return addDateField(item)
      case 'boolean':
        return addCheckBoxField(item)
      case 'singleselect':
        return addSelectField(item)
      default: 
        return addTextField(item);
    }

  }

  if(isloading)
    return (<h4>Loading data...</h4>)
  return (
    <React.Fragment>
      {typeof notes !== 'undefined' && selnote >= 0 && 
      <Dialog 
        open={(opennote && selnote >= 0)}
        onClose={handleCloseNote}
        maxWidth='xl'
        PaperProps={{
          component: 'form',
        }}
      >
        <DialogTitle>Note Details</DialogTitle>
        <DialogContent>
          <DialogContentText >Created on: {`${new Date(notes[selnote].date_added??'').toLocaleString()}`} </DialogContentText>
          <DialogContentText >By: {`${notes[selnote].added_by??''}\r\n\n`}<br/></DialogContentText>
          
          <Textarea className='postitnote' maxRows={20} cols={200} rows={20} defaultValue={notes[selnote].note??''} readOnly={true} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNote}>Close Note</Button>
        </DialogActions>
      </Dialog> }
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='xl'
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            handleClose();
          },
        }}
      >
        <DialogTitle>View/Edit Lead</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the Lead and Press Update to Commit the update or Cancel to close without updating.<br/>          
          </DialogContentText><br/>
          <strong>Contact Fields</strong><br/>
          {props.cols.filter(item=>item.fldtype==='core').map((item) => {
            return chooseConrol(item)
          })}
          <br/><strong>User Defined Fields</strong>
          {props.cols.filter(item=>item.fldtype==='user').map((item) => {
            return chooseConrol(item)
          })}
          <br/><strong>Information / Status</strong>
          {props.cols.filter(item=>item.fldtype==='status').map((item) => {
            return chooseConrol(item)
          })}
          <br/><strong>Add Notes</strong><br/>
          <Textarea className='notes' id='notes'  minRows={4} maxRows={6} rows={4} value={note} onChange={updateNote} />
          <Button size='sm' variat='solid' onClick={handleAddNote}>Add Note</Button>
          <br/><strong>Note History</strong><br/>
          <List variant="outlined" sx={{ minWidth: 240, borderRadius: 'sm' }}>
            {notes.map( (noteitem,index) => {
                return (<>
                    <ListItem size='sm' key={`notes_${index}`}>
                      <div className='note_date'>{`${new Date(noteitem.date_added?noteitem.date_added:new Date()).toLocaleString()}`}</div>
                      <div className='note_user' >{`${noteitem.added_by}`}</div><br/>
                      <div className='note_text' >{`${noteitem.note.substring(0,120).replaceAll('\n',' ')+' ...'}`}&nbsp;</div>
                      <div><DehazeSharpIcon onClick={() => {handleShowNote(index)}} /></div>
                    </ListItem> 
                    <ListDivider inset='gutter' />
                  </>
                )             
              })
            }
          </List>
          
        </DialogContent>
        <DialogActions>
          <TransitionAlert open={openalert} Close={setOpenAlert} message={alertmsg} />
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdate}>Update</Button><br/>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default RecordEdit