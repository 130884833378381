export const addDD = async (token, leadset, datadict) => {
  try {
    const url =`https://appserver.edwardspower.co.uk:3446/datadictionary/add`;
    const requestOptions = {
      method: 'POST',
      headers: new Headers({'Content-Type': 'application/json','Authorization' : 'Bearer ' + token }),
      body: JSON.stringify({ leadset, datadict})
    };

    const res = await fetch(url, requestOptions)
    let data = null
    if(res.status===200) {
      data = await res.json()
      return {status: true, data, err: ''}
    }
    return {status: false, data: null, err: `Add failed. ${res.msg} (${res.status}) `}
  }catch(err) {
    return {status: false, data: null, err: `CatchError: ${err} `}
  }
}
// Get Data Dictionaty Entries for a Given Dataset
export const getDD = async (token, leadset) => {
  try {
    const url =`https://appserver.edwardspower.co.uk:3446/datadictionary/get?leadset=${leadset}`;
    const requestOptions = {
      method: 'GET',
      headers: new Headers({'Content-Type': 'application/json','Authorization' : 'Bearer ' + token }),
    };

    const res = await fetch(url, requestOptions)
    let data = null
    if(res.status===200) {
      data = await res.json()
      return {status: data.status, data: data.data, err: ''}
    }
    return {status: false, data: null, err: `Get failed. ${res.msg} (${res.status}) `}
  }catch(err) {
    return {status: false, data: null, err: `CatchError: ${err} `}
  }
}

// Get All Data Dictionary datasets,
export const getAllDDs = async (token) => {
  try {
    const url =`https://appserver.edwardspower.co.uk:3446/datadictionary/getall`;
    const requestOptions = {
      method: 'GET',
      headers: new Headers({'Content-Type': 'application/json','Authorization' : 'Bearer ' + token }),
    };

    const res = await fetch(url, requestOptions)
    let data = null
    if(res.status===200) {
      data = await res.json()
      return {status: data.status, data: data.data, err: ''}
    }
    return {status: false, data: null, err: `Get failed. ${res.msg} (${res.status}) `}
  }catch(err) {
    return {status: false, data: null, err: `CatchError: ${err} `}
  }
}

// Get Lookup Table Data
export const getlkuptables = async (token, leadset, tablename) => {
  try {
    const url =`https://appserver.edwardspower.co.uk:3446/datadictionary/tablelookups?leadset=${leadset}&tablename=${tablename}`;
    const requestOptions = {
      method: 'GET',
      headers: new Headers({'Content-Type': 'application/json','Authorization' : 'Bearer ' + token }),
    };

    const res = await fetch(url, requestOptions)
    let data = null
    if(res.status===200) {
      data = await res.json()
      return {status: data.status, data: data.data, err: ''}
    }
    return {status: false, data: null, err: `Get failed. ${res.msg} (${res.status}) `}
  }catch(err) {
    return {status: false, data: null, err: `CatchError: ${err} `}
  }
}

// Get System Lookup Tables
export const getsystables = async (token, systable) => {
  try {
    const url =`https://appserver.edwardspower.co.uk:3446/datadictionary/systables?&systable=${systable}`;
    const requestOptions = {
      method: 'GET',
      headers: new Headers({'Content-Type': 'application/json','Authorization' : 'Bearer ' + token }),
    };

    const res = await fetch(url, requestOptions)
    let data = null
    if(res.status===200) {
      data = await res.json()
      return {status: data.status, data: data.data, err: ''}
    }
    return {status: false, data: null, err: `Get failed. ${res.msg} (${res.status}) `}
  }catch(err) {
    return {status: false, data: null, err: `CatchError: ${err} `}
  }
}

