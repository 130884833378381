import { configureStore } from '@reduxjs/toolkit';

import authSlice from '../slices/authSlice.js'
import systablesSlice from '../slices/systablesSlice.js'
import settingsSlice from '../slices/settingsSlice.js'
  
export default configureStore({
    reducer: {
        auth: authSlice,
        systables: systablesSlice,
        settings: settingsSlice,
    }
})