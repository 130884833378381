

export const getDateSetter = (value) => {
    try {
      return new Date(value).toLocaleString('en-GB', { timeZone: 'UTC' })
    }catch(err){
      return value
    }
  }

export const getNow = () => {
  const dt = new Date()
  const updatedt = dt.toISOString().substring(0,10) + "T" + dt.getHours().toString().padStart(2,'0') + ":" + dt.getMinutes().toString().padStart(2,'0') + ":" + dt.getSeconds().toString().padStart(2,'0') + "." + dt.getMilliseconds() + "Z";
  return updatedt;
}